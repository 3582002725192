var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"supplier-assignment","fluid":"","tag":"section"}},[(_vm.saving)?_c('div',{staticClass:"spinner-box"},[_c('md-progress-spinner',{staticClass:"color-stroke",attrs:{"md-mode":"indeterminate"}})],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[(!_vm.loading)?_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[(!_vm.isAccountVerified)?_c('div',{staticClass:"verification-alert-container"},[_c('div',[_c('div',[_vm._v(" Your company profile needs to be verified to add and remove suppliers. ")]),_c('request-verification-button',{on:{"request-verification":_vm.requestVerificationForAccount}})],1),_c('md-divider',{staticClass:"mb-5"})],1):_vm._e(),_vm._l((_vm.searchFilters),function(filter){return _c('div',{key:filter.header,staticClass:"filters-container",style:(`${
                _vm.activeTab !== _vm.tabs.INDIVIDUAL_SUPPLIERS.key
                  ? 'opacity: 0.3'
                  : ''
              }`)},[_c('div',{staticClass:"filter-header"},[_vm._v(_vm._s(filter.header))]),_vm._l((filter.items),function(item){return _c('v-checkbox',{key:item.value,staticClass:"filter-checkbox",attrs:{"true-value":_vm.selectedSearchFilters.find(
                    (filter) => filter === item.value
                  ),"label":item.name,"value":item.value,"disabled":_vm.activeTab !== _vm.tabs.INDIVIDUAL_SUPPLIERS.key},on:{"change":function($event){return _vm.selectSearchFilter(item.value, item.query || filter.query)}}})})],2)})],2),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('div',{staticClass:"top-header-tools"},[_c('div',{staticClass:"tab-buttons-container"},_vm._l((Object.values(_vm.tabs)),function(tab){return _c('div',{key:tab.key,class:`tab-button ${
                      _vm.activeTab === tab.key ? 'tab-button-active' : ''
                    }`,on:{"click":function($event){return _vm.onClickTab(tab.key)}}},[_vm._v(" "+_vm._s(tab.label)+" ")])}),0)])]),_c('div',{staticClass:"supplier-form"},[_c('div',{staticClass:"supplier-search-bar"},[_c('md-field',{staticClass:"field_size",staticStyle:{"margin-bottom":"10px"}},[_c('label',{staticClass:"color_class",attrs:{"for":"search-query"}},[_vm._v("Search")]),_c('md-input',{attrs:{"name":"search","id":"search","disabled":_vm.loading},on:{"input":_vm.debounceSearch,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),(_vm.activeTab === _vm.tabs.INDIVIDUAL_SUPPLIERS.key)?_c('v-row',{staticClass:"request-field-size"},[_c('span',{staticClass:"span-label"},[_c('a',{staticClass:"clickable-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleClick.apply(null, arguments)}}},[_vm._v(" I'm unable to find the business ")])]),_c('organization-request',{attrs:{"active":_vm.organizationRequesting},on:{"close":function($event){_vm.organizationRequesting = false}}})],1):_vm._e()],1),(
                _vm.activeTab === _vm.tabs.INDIVIDUAL_SUPPLIERS.key ||
                _vm.activeTab === _vm.tabs.CIRCULARITY_PARTNERS.key
              )?_c('div',[(
                  (_vm.activeTab === _vm.tabs.INDIVIDUAL_SUPPLIERS.key &&
                    _vm.suppliers.length > 0 &&
                    !_vm.loadingSuppliers) ||
                  (_vm.activeTab === _vm.tabs.CIRCULARITY_PARTNERS.key &&
                    _vm.circularityPartners.length > 0)
                )?_c('v-row',{staticClass:"supplier-list"},[_vm._l((_vm.activeTab ===
                  _vm.tabs.INDIVIDUAL_SUPPLIERS.key
                    ? _vm.suppliers
                    : _vm.circularityPartners),function(supplier){return _c('v-col',{key:supplier.id,attrs:{"cols":"12"}},[_c('div',{staticClass:"md-layout-item supplier-list__item"},[_c('div',{staticClass:"card-style",style:(!_vm.isAssignedSupplier(supplier.id)
                          ? 'background: #FFFFFF;'
                          : '')},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"supplier-card-container"},[_c('v-tooltip',{attrs:{"color":"#6A7895","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"verify-icon-container"},on),[_c('v-icon',{class:{
                                    'star-selected': supplier.favorite,
                                    'enlarge-star':
                                      _vm.hoveredStar === supplier.id,
                                  },staticStyle:{"margin-right":"10px"},on:{"mouseenter":function($event){_vm.hoveredStar = supplier.id},"mouseleave":function($event){_vm.hoveredStar = null},"click":function($event){return _vm.toggleFavorite(supplier)}}},[_vm._v(" "+_vm._s(supplier.favorite ? "mdi-star" : "mdi-star-outline")+" ")])],1)]}}],null,true)},[_c('div',{staticClass:"hover-container"},[_c('span',[_vm._v(_vm._s(supplier.favorite ? "Remove from favourites" : "Add to favourites"))])])]),_c('span',{staticClass:"supplier-name",on:{"click":function($event){return _vm.navigateToSupplierCompanyProfile(supplier)}}},[_vm._v(" "+_vm._s(supplier.name)+" "),(
                                supplier.account && supplier.account.verified
                              )?_c('v-tooltip',{attrs:{"color":"#6A7895","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"verify-icon-container"},on),[_c('verify-icon')],1)]}}],null,true)},[_c('div',{staticClass:"hover-container"},[_c('span',[_vm._v("Verified")])])]):_vm._e()],1)],1),_c('div',{staticClass:"value-chain-icons-container",staticStyle:{"margin-left":"40px"}},_vm._l((_vm.supplierAssignedValueChainIcons(
                              supplier.id
                            )),function(icon){return _c('ellipse-icon',{key:icon.icon,attrs:{"width":'30px',"height":'30px',"variant":"primary"}},[_c('trace-services-icon',{attrs:{"traceName":icon.icon,"ellipseSize":30,"iconSize":icon.size}})],1)}),1)]),(_vm.activeTab === _vm.tabs.INDIVIDUAL_SUPPLIERS.key)?_c('div',[(
                            _vm.activeTab === _vm.tabs.INDIVIDUAL_SUPPLIERS.key &&
                            !_vm.isAssignedSupplier(supplier.id)
                          )?_c('ui-button',{attrs:{"className":"primary short shadow","title":"Add","disabled":_vm.saving || !_vm.isAccountVerified},on:{"click":function($event){return _vm.assign(supplier)}}}):_c('ui-button',{attrs:{"small":"","className":"danger short shadow","title":"Remove","data-cy":"supplier_assignment__btn-unassign","disabled":_vm.saving || !_vm.isAccountVerified},on:{"click":() => {
                              if (
                                _vm.activeTab === _vm.tabs.INDIVIDUAL_SUPPLIERS.key
                              ) {
                                _vm.unassign(supplier);
                              }
                              _vm.removeCircularityPartner(supplier);
                            }}})],1):_vm._e()])])])}),(_vm.activeTab === _vm.tabs.INDIVIDUAL_SUPPLIERS.key)?_c('v-row',{staticClass:"ml-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pagination-style"},[_c('Pagination',{attrs:{"current-page":_vm.suppliersCurrentPage,"total-count":_vm.suppliersTotalCount},on:{"load-page":_vm.loadSuppliers}})],1)])],1):_vm._e()],2):(_vm.loadingSuppliers)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('md-progress-spinner',{staticClass:"color_stroke",attrs:{"md-mode":"indeterminate"}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("No suppliers found.")])],1)],1):(_vm.activeTab === _vm.tabs.VALUE_CHAINS.key)?_c('div',[(_vm.valueChains.length > 0 && !_vm.loadingValueChains)?_c('v-row',{staticClass:"supplier-list"},[_vm._l((_vm.valueChains),function(valueChain){return _c('v-col',{key:valueChain.id,attrs:{"cols":"12"}},[_c('div',{staticClass:"md-layout-item supplier-list__item"},[_c('div',{staticClass:"value-chain-card-style",style:(`min-height: 100px; background: ${
                        !_vm.assignedValueChain ||
                        _vm.assignedValueChain.id !== valueChain.id
                          ? '#FFFFFF'
                          : ''
                      }`)},[_c('v-tooltip',{attrs:{"color":"#6A7895","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"verify-icon-container",staticStyle:{"margin":"10px 0px 0px 10px"}},on),[_c('v-icon',{class:{
                                'star-selected': valueChain.is_favorite,
                                'enlarge-star':
                                  _vm.hoveredStar ===
                                  'value-chain-' + valueChain.id,
                              },on:{"mouseenter":function($event){_vm.hoveredStar = 'value-chain-' + valueChain.id},"mouseleave":function($event){_vm.hoveredStar = null},"click":function($event){return _vm.toggleValueChainFavorite(valueChain)}}},[_vm._v(" "+_vm._s(valueChain.is_favorite ? "mdi-star" : "mdi-star-outline")+" ")])],1)]}}],null,true)},[_c('div',{staticClass:"hover-container"},[_c('span',[_vm._v(_vm._s(valueChain.is_favorite ? "Remove from favourites" : "Add to favourites"))])])]),_c('div',{staticStyle:{"flex":"1","margin-right":"5px"}},[_c('div',{staticClass:"value-chain-name-container"},[_c('span',{staticClass:"value-chain-name"},[_vm._v(_vm._s(valueChain.name))])]),_c('div',[_c('truncate-hovering',{class:`creator-name${
                              !_vm.assignedValueChain ||
                              _vm.assignedValueChain.id !== valueChain.id
                                ? ''
                                : '-assigned'
                            }`,attrs:{"text":`Created by ${valueChain.account.organization_registry.name}`,"length":60}})],1),_c('div',{staticClass:"value-chain-header"},[_c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',{staticClass:"value-chain-label-container"},[_c('div',{staticClass:"value-label"},[_vm._v(" Transparency "+_vm._s(_vm.transparencyScore(valueChain))+"% ")]),_c('div',{staticClass:"value-label"},[_vm._v(" Circularity - "+_vm._s(_vm.circularityRange(valueChain))+" ")])])]),_c('div',{staticClass:"value-chain-buttons"},[_c('ui-button',{attrs:{"className":"primary-outlined short shadow","title":"View"},on:{"click":function($event){return _vm.viewValueChain(valueChain)}}}),(
                                !_vm.assignedValueChain ||
                                _vm.assignedValueChain.id !== valueChain.id
                              )?_c('ui-button',{attrs:{"className":"primary short shadow","title":"Add","disabled":_vm.saving || !_vm.isAccountVerified},on:{"click":function($event){return _vm.assignValueChain(valueChain)}}}):_c('ui-button',{attrs:{"className":"danger short shadow","title":"Remove","data-cy":"supplier_assignment__btn-unassign","disabled":_vm.saving || !_vm.isAccountVerified},on:{"click":function($event){return _vm.unassignValueChain(valueChain)}}})],1)]),_c('div',{staticClass:"value-chain-footer"},[_c('div',{staticClass:"value-chain-icons-container"},_vm._l((_vm.valueChainIcons(valueChain)),function(icon){return _c('ellipse-icon',{key:icon.icon,attrs:{"width":'30px',"height":'30px',"variant":"primary"}},[_c('trace-services-icon',{attrs:{"traceName":icon.icon,"ellipseSize":30,"iconSize":icon.size}})],1)}),1)])])],1)])])}),_c('v-row',{staticClass:"ml-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pagination-style"},[_c('Pagination',{attrs:{"current-page":_vm.valueChainsCurrentPage,"total-count":_vm.valueChainsTotalCount},on:{"load-page":_vm.loadValueChains}})],1)])],1)],2):(_vm.loadingValueChains)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('md-progress-spinner',{staticClass:"color_stroke",attrs:{"md-mode":"indeterminate"}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("No value chains found.")])],1)],1):_vm._e()])],1)],1):_vm._e()],1)],1),_c('popup-window',{attrs:{"active":_vm.openedValueChainItem,"hasClose":false,"width":"70vw","maxWidth":"70vw","maxHeight":"95vh","noMargin":""},on:{"popup-close":function($event){_vm.openedValueChainItem = null},"popup-back":function($event){_vm.openedValueChainItem = null}}},[_c('value-chain-item',{attrs:{"item":_vm.openedValueChainItem}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }