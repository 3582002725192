<template>
  <v-container id="supplier-assignment" fluid tag="section">
    <div v-if="saving" class="spinner-box">
      <md-progress-spinner
        class="color-stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <v-row justify="center">
      <v-col cols="12" class="mt-1">
        <!-- <div class="subtitle-4 font-weight-bold mb-5">
          Assign / Unassign suppliers
        </div> -->
        <v-container class="py-0" v-if="!loading">
          <v-row>
            <v-col cols="12" lg="6">
              <div
                class="verification-alert-container"
                v-if="!isAccountVerified"
              >
                <div>
                  <div>
                    Your company profile needs to be verified to add and remove
                    suppliers.
                  </div>
                  <request-verification-button
                    @request-verification="requestVerificationForAccount"
                  />
                </div>
                <md-divider class="mb-5" />
              </div>

              <div
                v-for="filter in searchFilters"
                :key="filter.header"
                class="filters-container"
                :style="`${
                  activeTab !== tabs.INDIVIDUAL_SUPPLIERS.key
                    ? 'opacity: 0.3'
                    : ''
                }`"
              >
                <div class="filter-header">{{ filter.header }}</div>
                <v-checkbox
                  v-for="item in filter.items"
                  :key="item.value"
                  :true-value="
                    selectedSearchFilters.find(
                      (filter) => filter === item.value
                    )
                  "
                  :label="item.name"
                  :value="item.value"
                  @change="
                    selectSearchFilter(item.value, item.query || filter.query)
                  "
                  :disabled="activeTab !== tabs.INDIVIDUAL_SUPPLIERS.key"
                  class="filter-checkbox"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <div>
                <div class="top-header-tools">
                  <div class="tab-buttons-container">
                    <div
                      v-for="tab in Object.values(tabs)"
                      :key="tab.key"
                      :class="`tab-button ${
                        activeTab === tab.key ? 'tab-button-active' : ''
                      }`"
                      @click="onClickTab(tab.key)"
                    >
                      {{ tab.label }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="supplier-form">
                <div class="supplier-search-bar">
                  <md-field class="field_size" style="margin-bottom: 10px">
                    <label class="color_class" for="search-query">Search</label>
                    <md-input
                      name="search"
                      id="search"
                      v-model="query"
                      @input="debounceSearch"
                      @keydown.enter.prevent
                      :disabled="loading"
                    />
                  </md-field>
                </div>
                <v-row
                  v-if="activeTab === tabs.INDIVIDUAL_SUPPLIERS.key"
                  class="request-field-size"
                >
                  <span class="span-label">
                    <a
                      href="#"
                      @click.prevent="handleClick"
                      class="clickable-link"
                    >
                      I'm unable to find the business
                    </a>
                  </span>
                  <organization-request
                    :active="organizationRequesting"
                    @close="organizationRequesting = false"
                  />
                </v-row>
              </div>
              <div
                v-if="
                  activeTab === tabs.INDIVIDUAL_SUPPLIERS.key ||
                  activeTab === tabs.CIRCULARITY_PARTNERS.key
                "
              >
                <v-row
                  class="supplier-list"
                  v-if="
                    (activeTab === tabs.INDIVIDUAL_SUPPLIERS.key &&
                      suppliers.length > 0 &&
                      !loadingSuppliers) ||
                    (activeTab === tabs.CIRCULARITY_PARTNERS.key &&
                      circularityPartners.length > 0)
                  "
                >
                  <v-col
                    cols="12"
                    v-for="supplier in activeTab ===
                    tabs.INDIVIDUAL_SUPPLIERS.key
                      ? suppliers
                      : circularityPartners"
                    :key="supplier.id"
                  >
                    <div class="md-layout-item supplier-list__item">
                      <div
                        class="card-style"
                        :style="
                          !isAssignedSupplier(supplier.id)
                            ? 'background: #FFFFFF;'
                            : ''
                        "
                      >
                        <div class="card-header">
                          <div class="supplier-card-container">
                            <v-tooltip color="#6A7895" bottom>
                              <template v-slot:activator="{ on }">
                                <div v-on="on" class="verify-icon-container">
                                  <v-icon
                                    @mouseenter="hoveredStar = supplier.id"
                                    @mouseleave="hoveredStar = null"
                                    @click="toggleFavorite(supplier)"
                                    :class="{
                                      'star-selected': supplier.favorite,
                                      'enlarge-star':
                                        hoveredStar === supplier.id,
                                    }"
                                    style="margin-right: 10px"
                                  >
                                    {{
                                      supplier.favorite
                                        ? "mdi-star"
                                        : "mdi-star-outline"
                                    }}
                                  </v-icon>
                                </div>
                              </template>
                              <div class="hover-container">
                                <span>{{
                                  supplier.favorite
                                    ? "Remove from favourites"
                                    : "Add to favourites"
                                }}</span>
                              </div>
                            </v-tooltip>
                            <span
                              class="supplier-name"
                              @click="
                                navigateToSupplierCompanyProfile(supplier)
                              "
                            >
                              {{ supplier.name }}
                              <v-tooltip
                                v-if="
                                  supplier.account && supplier.account.verified
                                "
                                color="#6A7895"
                                bottom
                              >
                                <template v-slot:activator="{ on }">
                                  <div v-on="on" class="verify-icon-container">
                                    <verify-icon />
                                  </div>
                                </template>
                                <div class="hover-container">
                                  <span>Verified</span>
                                </div>
                              </v-tooltip>
                            </span>
                          </div>
                          <div
                            class="value-chain-icons-container"
                            style="margin-left: 40px"
                          >
                            <ellipse-icon
                              v-for="icon in supplierAssignedValueChainIcons(
                                supplier.id
                              )"
                              :key="icon.icon"
                              :width="'30px'"
                              :height="'30px'"
                              variant="primary"
                            >
                              <trace-services-icon
                                :traceName="icon.icon"
                                :ellipseSize="30"
                                :iconSize="icon.size"
                              />
                            </ellipse-icon>
                          </div>
                        </div>
                        <div v-if="activeTab === tabs.INDIVIDUAL_SUPPLIERS.key">
                          <ui-button
                            className="primary short shadow"
                            title="Add"
                            v-if="
                              activeTab === tabs.INDIVIDUAL_SUPPLIERS.key &&
                              !isAssignedSupplier(supplier.id)
                            "
                            @click="assign(supplier)"
                            :disabled="saving || !isAccountVerified"
                          />
                          <ui-button
                            small
                            className="danger short shadow"
                            title="Remove"
                            data-cy="supplier_assignment__btn-unassign"
                            v-else
                            @click="
                              () => {
                                if (
                                  activeTab === tabs.INDIVIDUAL_SUPPLIERS.key
                                ) {
                                  unassign(supplier);
                                }
                                removeCircularityPartner(supplier);
                              }
                            "
                            :disabled="saving || !isAccountVerified"
                          />
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-row
                    v-if="activeTab === tabs.INDIVIDUAL_SUPPLIERS.key"
                    class="ml-2"
                  >
                    <v-col cols="12">
                      <div class="pagination-style">
                        <Pagination
                          :current-page="suppliersCurrentPage"
                          :total-count="suppliersTotalCount"
                          @load-page="loadSuppliers"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row v-else-if="loadingSuppliers">
                  <v-col cols="12" class="text-center">
                    <md-progress-spinner
                      class="color_stroke"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">No suppliers found.</v-col>
                </v-row>
              </div>
              <div v-else-if="activeTab === tabs.VALUE_CHAINS.key">
                <v-row
                  class="supplier-list"
                  v-if="valueChains.length > 0 && !loadingValueChains"
                >
                  <v-col
                    cols="12"
                    v-for="valueChain in valueChains"
                    :key="valueChain.id"
                  >
                    <div class="md-layout-item supplier-list__item">
                      <div
                        class="value-chain-card-style"
                        :style="`min-height: 100px; background: ${
                          !assignedValueChain ||
                          assignedValueChain.id !== valueChain.id
                            ? '#FFFFFF'
                            : ''
                        }`"
                      >
                        <v-tooltip color="#6A7895" bottom>
                          <template v-slot:activator="{ on }">
                            <div
                              v-on="on"
                              class="verify-icon-container"
                              style="margin: 10px 0px 0px 10px"
                            >
                              <v-icon
                                @mouseenter="
                                  hoveredStar = 'value-chain-' + valueChain.id
                                "
                                @mouseleave="hoveredStar = null"
                                @click="toggleValueChainFavorite(valueChain)"
                                :class="{
                                  'star-selected': valueChain.is_favorite,
                                  'enlarge-star':
                                    hoveredStar ===
                                    'value-chain-' + valueChain.id,
                                }"
                              >
                                {{
                                  valueChain.is_favorite
                                    ? "mdi-star"
                                    : "mdi-star-outline"
                                }}
                              </v-icon>
                            </div>
                          </template>
                          <div class="hover-container">
                            <span>{{
                              valueChain.is_favorite
                                ? "Remove from favourites"
                                : "Add to favourites"
                            }}</span>
                          </div>
                        </v-tooltip>
                        <div style="flex: 1; margin-right: 5px">
                          <div class="value-chain-name-container">
                            <span class="value-chain-name">{{
                              valueChain.name
                            }}</span>
                          </div>
                          <div>
                            <truncate-hovering
                              :class="`creator-name${
                                !assignedValueChain ||
                                assignedValueChain.id !== valueChain.id
                                  ? ''
                                  : '-assigned'
                              }`"
                              :text="`Created by ${valueChain.account.organization_registry.name}`"
                              :length="60"
                            />
                          </div>
                          <div class="value-chain-header">
                            <div style="margin-left: 10px">
                              <div class="value-chain-label-container">
                                <div class="value-label">
                                  Transparency
                                  {{ transparencyScore(valueChain) }}%
                                </div>
                                <div class="value-label">
                                  Circularity -
                                  {{ circularityRange(valueChain) }}
                                </div>
                              </div>
                            </div>
                            <div class="value-chain-buttons">
                              <ui-button
                                className="primary-outlined short shadow"
                                title="View"
                                @click="viewValueChain(valueChain)"
                              />
                              <ui-button
                                v-if="
                                  !assignedValueChain ||
                                  assignedValueChain.id !== valueChain.id
                                "
                                className="primary short shadow"
                                title="Add"
                                @click="assignValueChain(valueChain)"
                                :disabled="saving || !isAccountVerified"
                              />
                              <ui-button
                                v-else
                                className="danger short shadow"
                                title="Remove"
                                data-cy="supplier_assignment__btn-unassign"
                                @click="unassignValueChain(valueChain)"
                                :disabled="saving || !isAccountVerified"
                              />
                            </div>
                          </div>
                          <div class="value-chain-footer">
                            <div class="value-chain-icons-container">
                              <ellipse-icon
                                v-for="icon in valueChainIcons(valueChain)"
                                :key="icon.icon"
                                :width="'30px'"
                                :height="'30px'"
                                variant="primary"
                              >
                                <trace-services-icon
                                  :traceName="icon.icon"
                                  :ellipseSize="30"
                                  :iconSize="icon.size"
                                />
                              </ellipse-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-row class="ml-2">
                    <v-col cols="12">
                      <div class="pagination-style">
                        <Pagination
                          :current-page="valueChainsCurrentPage"
                          :total-count="valueChainsTotalCount"
                          @load-page="loadValueChains"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row v-else-if="loadingValueChains">
                  <v-col cols="12" class="text-center">
                    <md-progress-spinner
                      class="color_stroke"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">No value chains found.</v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <popup-window
      :active="openedValueChainItem"
      :hasClose="false"
      width="70vw"
      maxWidth="70vw"
      maxHeight="95vh"
      noMargin
      @popup-close="openedValueChainItem = null"
      @popup-back="openedValueChainItem = null"
    >
      <value-chain-item :item="openedValueChainItem" />
    </popup-window>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import debounce from "lodash/debounce";
import Pagination from "../../components/Pagination";
import store from "@/store";
import auth from "@/auth";
import VerifyIcon from "@/assets/icons/VerifyIcon.vue";
import ValueChainItem from "@/components/ValueChainItem.vue";
import RequestVerificationButton from "@/components/RequestVerificationButton.vue";
import _ from "lodash";
import {
  calculateTransparencyScore,
  calculateCircularityRange,
} from "../../utils/valueChainHelper";
import routes from "@/router/routes";

export default {
  name: "supplierAssignment",
  components: {
    Pagination,
    ValueChainItem,
    RequestVerificationButton,
  },
  data: () => ({
    model: {
      id: null,
      styleNumber: null,
      productType: null,
      category: [],
      quantity: null,
      noOfColors: null,
      material: null,
      brand_id: null,
      created_by_organization: null,
      selectedServices: [],
      serviceDeadlines: {},
      currency: null,
      additionalInformation: null,
      files: [],
    },
    organizationRequesting: false,
    productTypes: [],
    materials: [],
    currencies: [],
    services: [],
    categories: [],
    hoveredStar: null,
    saving: false,
    loading: false,
    loadingSuppliers: false,
    suppliers: [],
    loadingValueChains: false,
    valueChains: [],
    assignedSuppliers: [],
    assignedValueChain: null,
    traceRecords: [],
    donationRecords: [],
    repairRecords: [],
    upcycleRecords: [],
    recycleRecords: [],
    resaleRecords: [],
    query: "",
    suppliersCurrentPage: 1,
    valueChainsCurrentPage: 1,
    suppliersTotalCount: 0,
    valueChainsTotalCount: 0,
    openedValueChainItem: null,
    selectedSearchFilters: [],
    searchFilters: [
      {
        header: "Supplier feature",
        items: [
          { name: "Most popular companies", query: "popular", value: "true" },
          { name: "Verified Supplier", query: "verified", value: "true" },
          { name: "New", query: "new", value: "true" },
        ],
      },
      {
        header: "Certifications",
        query: "certification[]",
        items: [
          { name: "SEDEX", value: "sedex" },
          { name: "BSCI", value: "bsci" },
          { name: "Oeko-Tex", value: "oeko-tex" },
          { name: "ISO 9001", value: "iso 9001" },
          { name: "GOTS", value: "gots" },
        ],
      },
    ],
    tabs: {
      VALUE_CHAINS: {
        key: "valueChains",
        label: "Value Chains",
      },
      INDIVIDUAL_SUPPLIERS: {
        key: "individualSuppliers",
        label: "Individual Suppliers",
      },
      CIRCULARITY_PARTNERS: {
        key: "circularityPartners",
        label: "Circularity Partners",
      },
    },
    activeTab: "valueChains",
  }),
  watch: {
    activeTab() {
      this.suppliersCurrentPage = 1;
      this.valueChainsCurrentPage = 1;
      this.decideBetweenGetSuppliersAndGetValueChains();
    },
    "$store.state.valueChainAssignStatus": {
      handler: function () {
        this.assignedValueChain = null;
        this.saving = false;
        notification.success("Value chain unassigned successfully");
        this.$emit("supplier-assigned", 1);
        store.commit(
          "SET_UPDATE_INQUIRY_SUPPLIER",
          !this.$store.state.inquirySuppliersUpdated
        );
      },
      deep: true,
    },
    selectedSearchFilters: {
      handler() {
        this.suppliersCurrentPage = 1;
        this.valueChainsCurrentPage = 1;
        this.decideBetweenGetSuppliersAndGetValueChains();
      },
      deep: true,
    },
  },
  mounted() {
    const inquiryId = this.$route.params.id;

    this.getInquiry(inquiryId).then((response) => {
      const inquiry = response.data.inquiry;

      this.model.id = inquiry.id;
      this.model.styleNumber = inquiry.style_number;
      this.model.productType = inquiry.product_type;
      this.model.category = inquiry.categories;
      this.model.quantity = inquiry.bulk_order_quantity;
      this.model.noOfColors = inquiry.colour_number;
      this.model.material = inquiry.materials;
      this.model.currency = inquiry.currency;
      this.model.additionalInformation = inquiry.additional_information;
      this.model.createdBy = inquiry.created_by;
      this.model.accountManagerId = inquiry.account_manager_id;
      this.model.created_by_organization =
        inquiry.collection.created_by_organization_id;
      this.model.brand_id = inquiry.collection.brand_id;

      // TODO: service deadlines are not retreive correclty

      this.assignedSuppliers = [];
      response.data.suppliers.forEach((supplier) => {
        supplier.id = supplier.supplier_id;
        this.assignedSuppliers.push(supplier);
      });
      this.assignedValueChain =
        inquiry.inquiry_value_chain?.account_value_chain;
      this.setNonConsumerTraceAndCircularDetails(response.data.inquiry);
    });

    this.getProductTypes().then((response) => {
      this.productTypes = response.data.productTypes;
    });
    this.getMaterials().then((response) => {
      this.materials = response.data.materials;
    });
    this.getCurrencies().then((response) => {
      this.currencies = response.data.currencies;
    });
    this.getCategories().then((response) => {
      this.categories = response.data.categories;
    });

    this.decideBetweenGetSuppliersAndGetValueChains();
  },
  computed: {
    isAccountVerified() {
      return auth.user.account?.verified;
    },
    circularityPartners() {
      const circularityPartners = _.uniqBy(
        [
          ...this.traceRecords.map((trace) => trace.supplier),
          ...this.donationRecords.map((donation) => donation.supplier),
          ...this.repairRecords.map((repair) => repair.supplier),
          ...this.upcycleRecords.map((upcycle) => upcycle.supplier),
          ...this.recycleRecords.map((recycle) => recycle.supplier),
          ...this.resaleRecords.map((resale) => resale.supplier),
        ].filter((partner) => partner),
        "id"
      ).sort((a, b) => a.name.localeCompare(b.name));

      if (this.query) {
        const regEx = new RegExp(`\\b${this.query}`, "i");
        return circularityPartners.filter((partner) =>
          regEx.test(partner.name)
        );
      }

      return circularityPartners;
    },
  },
  methods: {
    selectSearchFilter(value, query) {
      if (
        this.selectedSearchFilters.find(
          (filter) => filter.value === value && filter.query === query
        )
      ) {
        this.selectedSearchFilters = this.selectedSearchFilters.filter(
          (filter) => filter.value !== value || filter.query !== query
        );
      } else {
        this.selectedSearchFilters.push({ value, query });
      }
    },
    onClickTab(tabKey) {
      this.activeTab = tabKey;
    },
    debounceSearch: debounce(function () {
      this.suppliersCurrentPage = 1;
      this.loadingSuppliers = true;
      this.decideBetweenGetSuppliersAndGetValueChains();
    }, 500),
    back_to_inquiry() {
      this.$emit("show-options", 1);
    },
    decideBetweenGetSuppliersAndGetValueChains() {
      if (this.activeTab === this.tabs.INDIVIDUAL_SUPPLIERS.key) {
        this.getSuppliers(auth.user.account.organization_registry_id);
      } else {
        this.getValueChains();
      }
    },
    valueChainIcons(valueChain) {
      const traceIcons = valueChain.value_chain_traces.map((trace) => {
        return {
          size: 18,
          icon: trace.trace.icon,
        };
      });
      if (valueChain.value_chain_donations?.length > 0) {
        traceIcons.push({
          size: 18,
          icon: "DonationsIcon",
        });
      }
      if (valueChain.value_chain_repairs?.length > 0) {
        traceIcons.push({
          size: 12,
          icon: "RepairsIcon",
        });
      }
      if (valueChain.value_chain_upcycles?.length > 0) {
        traceIcons.push({
          size: 15,
          icon: "UpcyclesIcon",
        });
      }
      if (valueChain.value_chain_recycles?.length > 0) {
        traceIcons.push({
          size: 15,
          icon: "RecyclesIcon",
        });
      }
      if (valueChain.value_chain_resales?.length > 0) {
        traceIcons.push({
          size: 10,
          icon: "ResalesIcon",
        });
      }

      return traceIcons;
    },
    supplierAssignedValueChainIcons(supplierId) {
      const supplierAssignedItems = {
        value_chain_traces: this.traceRecords.filter(
          (trace) => trace.supplier_id === supplierId
        ),
        value_chain_donations: this.donationRecords.filter(
          (donation) => donation.supplier_id === supplierId
        ),
        value_chain_repairs: this.repairRecords.filter(
          (repair) => repair.supplier_id === supplierId
        ),
        value_chain_upcycles: this.upcycleRecords.filter(
          (upcycle) => upcycle.supplier_id === supplierId
        ),
        value_chain_recycles: this.recycleRecords.filter(
          (recycle) => recycle.supplier_id === supplierId
        ),
        value_chain_resales: this.resaleRecords.filter(
          (resale) => resale.supplier_id === supplierId
        ),
      };

      return this.valueChainIcons(supplierAssignedItems);
    },
    getSuppliers(organizationId) {
      this.loadingSuppliers = true;
      let url = `/api/organization_registry?inquiry_id=${this.$route.params.id}&page=${this.suppliersCurrentPage}&type=supplier`;
      if (this.query) {
        url += "&name=" + this.query;
      }
      url += "&organizationId=" + organizationId;

      for (const filter of this.selectedSearchFilters) {
        url += `&${filter.query}=${filter.value}`;
      }

      restAdapter.get(url).then((response) => {
        this.suppliers = response.data.data;
        this.suppliersTotalCount = response.data.total;
        this.loadingSuppliers = false;
      });
    },
    getValueChains() {
      this.loadingValueChains = true;
      restAdapter
        .get(
          `/api/value_chains?inquiry_id=${this.$route.params.id}&page=${
            this.valueChainsCurrentPage
          }${this.query ? "&query=" + this.query : ""}`
        )
        .then((response) => {
          this.valueChains = response.data.data;
          this.valueChainsTotalCount = response.data.total;
          this.loadingValueChains = false;
        });
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getProductTypes() {
      return restAdapter.get("/api/inquire_product_types");
    },
    getMaterials() {
      return restAdapter.get("/api/inquire_materials");
    },
    getCurrencies() {
      return restAdapter.get("/api/inquire_currencies");
    },
    getCategories() {
      return restAdapter.get("/api/inquire_categories");
    },
    getProductTypeName(id) {
      let name = "";
      this.productTypes.forEach((productType) => {
        if (productType.id == id) {
          name = productType.name;
        }
      });

      return name;
    },
    getCategoryName(id) {
      let name = "";
      this.categories.forEach((category) => {
        if (category.id == id) {
          name = category.name;
        }
      });

      return name;
    },
    openOrganizationRequest() {
      this.organizationRequesting = true;
    },
    handleClick() {
      this.openOrganizationRequest();
    },
    getMaterialName(id) {
      let name = "";
      this.materials.forEach((material) => {
        if (material.id == id) {
          name = material.name;
        }
      });

      return name;
    },
    getCurrencyName(code) {
      let name = "";
      this.currencies.forEach((currency) => {
        if (currency.currency_code == code) {
          name = currency.display_name;
        }
      });

      return name;
    },
    isAssignedSupplier(supplierId) {
      const supplier = this.assignedSuppliers.find(
        (supplier) => supplier.supplier_id === supplierId
      );
      return supplier !== undefined;
    },
    assign(supplier) {
      this.saving = true;
      const data = {
        supplier_id: supplier.id,
      };

      restAdapter
        .post(`/api/inquiries/${this.model.id}/assign_supplier`, data)
        .then((response) => {
          notification.success(supplier.name + " added successfully");
          this.$emit("supplier-assigned", 1);
          store.commit(
            "SET_UPDATE_INQUIRY_SUPPLIER",
            !this.$store.state.inquirySuppliersUpdated
          );
        })
        .then(() => {
          this.getInquiry(this.model.id).then((response) => {
            this.assignedSuppliers = [];
            response.data.suppliers.forEach((supplier) => {
              supplier.id = supplier.supplier_id;
              this.assignedSuppliers.push(supplier);
            });
            this.saving = false;
          });
        });
    },
    toggleFavorite(supplier) {
      const data = {
        supplierId: supplier.id,
      };

      restAdapter
        .post("/api/favourite_suppliers", data)
        .then((response) => {
          const favorited = response.data.favorited;

          if (favorited) {
            supplier.favorite = true;
            notification.success(`Added ${supplier.name} to favorites`);
          } else {
            supplier.favorite = false;
            notification.success(`Removed ${supplier.name} from favorites`);
          }
        })
        .catch((error) => {
          notification.error("Error while toggling favorite:", error);
        });
    },
    toggleValueChainFavorite(valueChain) {
      const data = {
        value_chain_id: valueChain.id,
      };

      restAdapter
        .post("/api/favorite_value_chains", data)
        .then((response) => {
          const favored = response.data.favorite;

          if (favored) {
            valueChain.is_favorite = true;
            notification.success(`Added ${valueChain.name} to favorites`);
          } else {
            valueChain.is_favorite = false;
            notification.success(`Removed ${valueChain.name} from favorites`);
          }
        })
        .catch((error) => {
          notification.error("Error while toggling favorite:", error);
        });
    },
    unassign(supplier) {
      this.saving = true;
      const data = {
        supplier_id: supplier.id,
      };

      const isSupplierOnValueChain = this.isSupplierOnAssignedTraceValueChain(
        supplier.id
      );

      const confirmed = confirm(
        `The supplier will also be removed from BOM, Digital Product Passport and Timeline. ${
          isSupplierOnValueChain
            ? "The added value chain will also be removed. "
            : ""
        }Are you sure you want to remove '${supplier.name}'?`
      );

      if (!confirmed) {
        this.saving = false;
        return;
      }

      restAdapter
        .delete(`/api/inquiries/${this.model.id}/remove_supplier`, data)
        .then(() => {
          notification.success(supplier.name + " removed successfully");
          this.$emit("supplier-assigned", 1);
          store.commit(
            "SET_UPDATE_INQUIRY_SUPPLIER",
            !this.$store.state.inquirySuppliersUpdated
          );
        })
        .then(() => {
          this.getInquiry(this.model.id).then((response) => {
            this.assignedSuppliers = [];
            response.data.suppliers.forEach((supplier) => {
              supplier.id = supplier.supplier_id;
              this.assignedSuppliers.push(supplier);
            });
            this.assignedValueChain =
              response.data.inquiry.inquiry_value_chain?.account_value_chain;

            this.saving = false;
          });
          if (
            auth.user.account.organization_registry_id === supplier.id &&
            supplier.id !== this.model.created_by_organization &&
            supplier.id !== this.model.brand_id
          ) {
            this.$router.push("Collections");
            return;
          }
        });
    },
    removeCircularityPartner(supplier) {
      if (this.isValueChainCircularityPartner(supplier)) {
        const confirmRemoval = window.confirm(
          "This circularity partner is assigned to the value chain. Removing them will unassign the current value chain. Do you want to proceed?"
        );

        if (confirmRemoval) {
          this.unassignValueChain(this.assignedValueChain);
          notification.success("Circularity partner successfully removed.");
        }
      } else {
        console.log("Circularity partner not found in value chain.");
      }
    },

    isValueChainCircularityPartner(supplier) {
      if (!this.assignedValueChain) {
        return false;
      }

      const fieldsToCheck = [
        "value_chain_donations",
        "value_chain_recycles",
        "value_chain_repairs",
        "value_chain_resales",
        "value_chain_upcycles",
      ];

      for (let i = 0; i < fieldsToCheck.length; i++) {
        const field = fieldsToCheck[i];
        if (this.assignedValueChain[field]) {
          for (let j = 0; j < this.assignedValueChain[field].length; j++) {
            if (this.assignedValueChain[field][j].supplier_id === supplier.id) {
              return true;
            }
          }
        }
      }

      return false;
    },

    viewValueChain(valueChain) {
      this.openedValueChainItem = null;
      this.openedValueChainItem = {
        id: valueChain.id,
        name: valueChain.name,
        productType: valueChain.product_type.name,
        description: valueChain.description,
        values: this.getValues(valueChain),
      };
    },
    isSupplierOnAssignedTraceValueChain(supplierId) {
      if (!this.assignedValueChain) {
        return false;
      }
      const traces = this.assignedValueChain.value_chain_traces;
      for (let i = 0; i < traces.length; i++) {
        if (traces[i].supplier_id === supplierId) {
          return true;
        }
      }
      return false;
    },
    getValues(valueChain) {
      const traces = {};
      valueChain.value_chain_traces.forEach((trace) => {
        traces[_.camelCase(trace.trace.display_name)] = trace.supplier;
      });
      const values = {
        donations: valueChain.value_chain_donations.map(
          (donation) => donation.supplier
        ),
        repairs: valueChain.value_chain_repairs.map(
          (repair) => repair.supplier
        ),
        upcycles: valueChain.value_chain_upcycles.map(
          (upcycle) => upcycle.supplier
        ),
        recycles: valueChain.value_chain_recycles.map(
          (recycle) => recycle.supplier
        ),
        resales: valueChain.value_chain_resales.map(
          (resale) => resale.supplier
        ),
        ...traces,
      };
      return values;
    },
    assignValueChain(valueChain) {
      this.saving = true;
      const confirmed =
        !this.assignedValueChain ||
        confirm(
          `The previously added value chain '${this.assignedValueChain.name}' will be removed, and the trace will be overwritten by the new value chain '${valueChain.name}'. If you have a published trace, it will not be affected by this change. Are you sure you want to remove '${valueChain.name}'?`
        );

      if (!confirmed) {
        this.saving = false;
        return;
      }

      restAdapter
        .post(`/api/inquiries/${this.model.id}/assign_value_chain`, {
          value_chain_id: valueChain.id,
        })
        .then(() => {
          this.assignedValueChain = valueChain;
          notification.success("Value chain added successfully");
          this.$emit("supplier-assigned", 1);
          store.commit(
            "SET_UPDATE_INQUIRY_SUPPLIER",
            !this.$store.state.inquirySuppliersUpdated
          );
          this.getInquiry(this.model.id).then((response) => {
            this.assignedSuppliers = [];
            response.data.suppliers.forEach((supplier) => {
              supplier.id = supplier.supplier_id;
              this.assignedSuppliers.push(supplier);
            });
            this.setNonConsumerTraceAndCircularDetails(response.data.inquiry);
            this.$emit("value-chain-assigned", this.assignedValueChain);
            this.saving = false;
          });
        });
    },
    setNonConsumerTraceAndCircularDetails(inquiryData) {
      this.traceRecords = inquiryData.non_consumer_traces;
      this.donationRecords = inquiryData.non_consumer_donations;
      this.repairRecords = inquiryData.non_consumer_repairs;
      this.upcycleRecords = inquiryData.non_consumer_upcycles;
      this.recycleRecords = inquiryData.non_consumer_recycles;
      this.resaleRecords = inquiryData.non_consumer_resales;
    },
    unassignValueChain(valueChain) {
      this.saving = true;
      const confirmed = confirm(
        "Are you sure you want to remove '" + valueChain.name + "'?"
      );

      if (!confirmed) {
        this.saving = false;
        return;
      }
      restAdapter
        .delete(`/api/inquiries/${this.model.id}/remove_value_chain`, {
          value_chain_id: valueChain.id,
        })
        .then(() => {
          this.assignedValueChain = null;
          this.saving = false;
          notification.success("Value chain removed successfully");
          this.$emit("supplier-assigned", 1);
          this.$emit("value-chain-assigned", null);
          store.commit(
            "SET_UPDATE_INQUIRY_SUPPLIER",
            !this.$store.state.inquirySuppliersUpdated
          );
        });
    },
    requestVerificationForAccount() {
      const id = auth.user.account?.organization_registry_id;
      if (!id) {
        return;
      }
      restAdapter
        .post(`/api/organization_verification_requests/${id}`)
        .then(() => {
          store.commit("SET_ACCOUNT_VERIFICATION_REQUESTED", true);
          notification.success(
            "Profile Verification request sent to Seamless Source"
          );
        })
        .catch((error) => {
          notification.error("Something went wrong, please try again later.");
        });
    },
    loadSuppliers(page) {
      this.suppliersCurrentPage = page;
      this.getSuppliers(auth.user.account.organization_registry_id);
    },
    loadValueChains(page) {
      this.valueChainsCurrentPage = page;
      this.getValueChains();
    },

    transparencyScore(valueChain) {
      return calculateTransparencyScore(this.getValues(valueChain));
    },
    circularityRange(valueChain) {
      return _.startCase(calculateCircularityRange(this.getValues(valueChain)));
    },
    navigateToSupplierCompanyProfile(supplier) {
      window.open(
        this.$router.resolve({
          name: routes.COMPANY_PROFILE,
          params: { companyUuid: supplier.uuid },
        }).href,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.supplier-form {
  justify-content: center;
  margin-bottom: 40px;
}

.empty-layout-item {
  margin-top: -48px;
}

.form-label {
  color: $primaryDark !important;
  font-weight: 400;
  text-align: right;
}

.enlarge-star {
  transform: scale(1.6);
  transition: transform 0.2s ease;
}

.form-value {
  color: $primaryDark;
  font-weight: 400;
  text-align: left;
}

.supplier-list {
  .md-title,
  .md-subhead {
    color: $primaryDark;
    text-align: left;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    text-decoration: none;
    background-color: $cardBackground;
  }
}
.verify-icon-container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1px;
}
.supplier-search-bar {
  text-align: right;
  display: flex;
}
.verify-icon-container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1px;
}
.search_class {
  width: 140px;
  height: 36px;
  border-radius: 3px;
}
.spinner-box {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 999;
}

.supplier-list-layout {
  padding: 10px 0 10px 15px;
}

a {
  color: #442d65 !important;
}
.star-selected {
  color: gold;
}
.supplier-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
}
.supplier-name {
  flex: 1;
  align-content: center !important;
  color: $primaryDark;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  word-wrap: anywhere;
}
.supplier-name:hover {
  cursor: pointer;
  text-decoration: underline;
}
.value-chain-name {
  @extend .supplier-name;
  &:hover {
    cursor: default;
    text-decoration: none;
  }
}
.creator-name {
  padding: 0px 10px;
  color: #bfb7ca;
  text-align: left;
  font-size: 14px;
  margin-top: -2px;
  margin-bottom: 5px;
}
.creator-name-assigned {
  @extend .creator-name;
  color: #473068;
}

.value-chain-card-style {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 10px;
  width: 513px;
  min-height: 55px;
  border-radius: 3px;
  border: 0.5px solid $primaryDarkest;
  background: $light;
  box-sizing: border-box;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.4);
}

.card-style {
  @extend .value-chain-card-style;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
}

.card-header {
  display: flex;
  flex-direction: column;
}

.supplier-brand {
  color: $primaryDark;
  text-align: left;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: $spinnerStroke !important;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: $primaryDarkest !important;
}

.list_color.md-theme-default::v-deep .md-list-item-text {
  color: $primaryDarkest !important;
}

.list_color.md-selected::v-deep .md-checked.md-primary .md-checkbox-container {
  background-color: transparent !important;
  border-color: $primaryDarkest !important;
  border-radius: 4px !important;
}

.list_color::v-deep .md-checkbox.md-theme-default .md-checkbox-container {
  background-color: transparent !important;
  border-color: $primaryDarkest !important;
  border-radius: 4px !important;
}

.list_color.md-selected::v-deep
  .md-checkbox.md-theme-default.md-checked
  .md-checkbox-container:after {
  border-color: $primaryDarkest !important;
}

.supplier_roles_container {
  padding-top: 10px;
  margin-top: -10px !important;
  background-color: $popupWindow;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1);
  border-radius: 4.25385px;
}

.color_class {
  border: none;
  color: $primaryDarkest !important;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.md-field.md-theme-default:before {
  background-color: $primaryDarkest !important;
}

.text_color {
  justify-content: center !important;
  font-style: normal !important;
  font-weight: 600;
  font-size: 13.7378px;
  line-height: 116.1%;
  display: flex;
  align-items: center;
  color: $primaryDark !important;
}

.subheading_text {
  color: $primaryDark;
  font-weight: 600;
  margin-bottom: 30px;
  margin-right: 180px;
  text-decoration-line: underline;
}
.assigned_suppliers_subheading_text {
  color: $assignButton;
  font-weight: 600;
  margin-bottom: 30px;
  text-decoration-line: underline;
}

.arrow_icon {
  font-size: 25px;
  color: $primaryDarkest;
}

.back_btn_class {
  width: 149px;
  height: 36px;
  margin-top: -5px;
  margin-left: 17px;
  background-color: rgba(71, 48, 104, 0.1) !important;
  border-radius: 81.2308px;
}

.back_btn_class:hover {
  cursor: pointer;
}

.md-card {
  box-shadow: none !important;
}
.unassign_btn_class {
  margin-top: -2px;
  align-content: center !important;
  box-shadow: none !important;
  width: 117.81px;
  height: 32px;
  border-radius: 2.71875px;
}
.btn_class {
  margin-left: 35px;
  align-content: center !important;
  box-shadow: none !important;
  width: 117.81px;
  height: 32px;
  border-radius: 2.71875px;
}

.field_size {
  width: 100% !important;
  margin-right: 40px;
}

.pagination-style {
  margin-top: 5px;
  margin-left: 0px;
  width: 513px;
}

.supplier-brand-name {
  color: $primaryDark;
}

.request-field-size {
  margin: -5px 40px 10px 0px;
  justify-content: left;
}

.verification-alert-container > div {
  padding: 0px 0px 15px 0px;
}
.verification-alert-container div {
  color: $dangerBackground;
}
.value-chain-footer {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
}
.value-chain-icons-container {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  gap: 5px;
  margin-top: 10px;
  flex-wrap: wrap;
  margin: 10px;
}
.value-chain-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.value-chain-name-container {
  width: 100%;
  padding: 10px 10px 0px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.value-chain-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0px;
  margin-bottom: -6px;
}
.top-header-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}
.tab-buttons-container {
  align-self: flex-start;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #faf7ff;
  border-radius: 20px;
  border: 1px solid #473068;
  margin-right: 40px;
  margin-bottom: 10px;
}
.tab-button {
  height: 30px;
  width: calc(100% / 3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #473068;
  cursor: pointer;
}
.tab-button-active {
  background-color: white;
  border: 1px solid #473068;
  border-radius: 15px;
  cursor: initial;
}

.value-chain-label-container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.value-label {
  background-color: $primaryLight;
  color: $primaryDarkest;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 40px;
}
.filter-checkbox {
  margin-top: 0px;
}
.filter-header {
  font-size: 16px;
  color: $primaryDark;
  text-align: left;
  font-weight: bolder;
  margin-bottom: 20px;
}
.filters-container {
  font-size: 15px;
  .filter-checkbox:last-child {
    margin-bottom: 20px;
  }
}
.filter-checkbox::v-deep label {
  color: $primaryDark;
  font-weight: bold;
}
.filter-checkbox::v-deep .v-messages {
  display: none;
}
</style>
